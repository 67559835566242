/* eslint-disable jsx-a11y/role-supports-aria-props */
import React from "react"
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import InfografiaXL from "src/assets/svg/infographics-xl.svg";
import InfografiaMD from "src/assets/svg/infographics-md.svg";
import InfografiaSM from "src/assets/svg/infographics-sm.svg";

const Infographics = () => {
 const theme = useTheme();
 const md = useMediaQuery(theme.breakpoints.down('md'))
 const lg = useMediaQuery(theme.breakpoints.down('lg'))

 const styles = {
  container: {
   padding: "40px 0px !important",
   display: 'flex',
   columnGap: 8,
   flexDirection: 'column',
   justifyContent: 'center',
   alignItems: "center"
  },
  titleContainer: {
   width: "100%",
   padding: "40px 0px",
  },
 }
 
 return (
  <Container sx={styles.container}>
   <Box sx={styles.titleContainer}>
    <Typography color="primary.dark" variant="h3" align="center">Anticipos</Typography>
   </Box>
   <Box style={{ display: "flex", justifyContent: "center", bottom: 0, width: '100%'}}>
    <Box component="img" src={md ? InfografiaSM : ( lg ? InfografiaMD : InfografiaXL)} sx={{ maxWidth: "100%"}}/>
   </Box>
  </Container>
 )}

export { Infographics }